import http from "@/utils/http";

const BASE_URL = "/";
/**
 * 登入
 * @param {*} params
 * @returns
 */
export function login(params) {
  return http.post(BASE_URL + "admin/login", params);
}

/**
 * 获取验证码
 * @param {*} params
 * @returns
 */
export function captcha(params) {
  return http.get(BASE_URL + "common/getCaptcha", params);
}

/**
 * 题材管理获取
 * @param {*} pageNum
 * @param {*} pageSize
 * @returns
 */
export function theme_get(pageNum, pageSize) {
  const params = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  return http.get(BASE_URL + "admin/theme", params);
}
export function theme_post(params) {
  return http.post(BASE_URL + "admin/theme", params);
}
export function theme_put(params) {
  return http.post(BASE_URL + "admin/theme/update", params);
}
export function theme_delete(id) {
  const params = {
    id: id,
  };
  return http.delete(BASE_URL + "admin/theme", params);
}

/**
 * 新闻相关
 * @param {*} pageNum
 * @param {*} pageSize
 * @returns
 */
export function news_get(pageNum, pageSize) {
  const params = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  console.log(BASE_URL);
  return http.get(BASE_URL + "admin/news", params);
}
export function news_post(params) {
  return http.post(BASE_URL + "admin/news", params);
}
export function news_put(params) {
  return http.post(BASE_URL + "admin/news/update", params);
}
export function news_delete(id) {
  const params = {
    id: id,
  };
  return http.delete(BASE_URL + "admin/news", params);
}

/**
 * 配置相关
 * @param {*} pageNum
 * @param {*} pageSize
 * @returns
 */
export function config_get(pageNum, pageSize) {
  const params = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  return http.get(BASE_URL + "admin/config", params);
}
export function config_post(params) {
  return http.post(BASE_URL + "admin/config", params);
}
export function config_put(params) {
  return http.post(BASE_URL + "admin/config/update", params);
}
export function config_delete(id) {
  const params = {
    id: id,
  };
  return http.delete(BASE_URL + "admin/config", params);
}

/**
 * 管理员管理
 * @returns
 */
export function sysUser_get() {
  const params = {};
  return http.get(BASE_URL + "admin/user", params);
}
export function sysUser_post(params) {
  return http.post(BASE_URL + "admin/user", params);
}
export function sysUser_put(params) {
  return http.put(BASE_URL + "admin/user", params);
}
export function sysUser_delete(params) {
  return http.delete(BASE_URL + "admin/user", params);
}

/**
 * 获取用户
 * @param {*} params
 * @returns
 */
export function api_getCustomerUser(params) {
  return http.get(BASE_URL + "admin/customerUser", params);
}

/**
 * 更改用户
 * @param {*} params
 * @returns
 */
export function api_updateCustomerUser(params) {
  return http.put(BASE_URL + "admin/customerUser", params);
}
