import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/common.css";
import Quill from "quill";
import ImageResize from "quill-image-resize";

Quill.register("modules/imageResize", ImageResize);

Vue.use(ElementUI);
Vue.config.productionTip = false;

// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */

//   console.log(to.meta.title)
//   if (to.meta.title) {
//     document.title = to.meta.title

//   }
//   next()
// })

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
